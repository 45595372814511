<script setup lang="ts">
import { type Ref, ref } from 'vue';
import { navigateTo, useCookie, useNuxtApp } from '#app';
import { useSidebarStore } from '~/shared/stores/sidebar.store';
import { sessionTokenKey } from '~/types/session';

const sidebarStore = useSidebarStore();

interface MenuElement {
  name: string
  icon?: string
  to?: string
  children?: MenuElement[]
  isOpened: boolean
}

const menuElements = ref([
  {
    name: 'Products',
    icon: 'ic:round-apps',
    to: '/products',
  },
  {
    name: 'Orders',
    icon: 'ic:round-format-list-bulleted',
    to: '/',
  },
  {
    name: 'Billing',
    icon: 'ic:round-attach-money',
    children: [
      {
        name: 'Update Methods',
        to: '/billing/update-methods',
      },
      {
        name: 'How does billing work?',
        to: '/billing/how-works',
      },
    ],
    isOpened: false,
  },
  {
    name: 'Settings',
    icon: 'ic:round-settings',
    children: [
      {
        name: 'Profile Settings',
        to: '/settings/profile',
      },
      {
        name: 'Company Settings',
        to: '/settings/company',
      },
      {
        name: 'Orders Settings',
        to: '/settings/orders',
      },
    ],
    isOpened: false,
  },
  {
    name: 'Help',
    icon: 'ic:round-help',
    to: '/help',
  },
] as MenuElement[]);

function logout() {
  const token = useCookie(sessionTokenKey);

  token.value = '';
  navigateTo('/login');
}

const menuHtmlElements = ref<HTMLElement[]>([]);

function getElementExpandedHeight(element: MenuElement, idx: number) {
  const content: HTMLElement = menuHtmlElements.value[idx].querySelector('.menu-element-inner');

  return element.isOpened ? content.clientHeight : 0;
}
</script>

<template>
  <Teleport to="body">
    <Transition
      name="sidebar"
      class="transition-opacity-600"
      enter-active-class="opacity-100" leave-active-class="opacity-0"
      enter-from-class="opacity-0" leave-to-class="opacity-0"
    >
      <div
        v-if="sidebarStore.isOpened"
        class="fixed top-0 left-0 w-100vw h-100vh bg-black/15 z-110"
        @click="sidebarStore.close"
      />
    </Transition>
  </Teleport>
  <aside
    class="h-100vh lg:w-52 xl:w-65 bg-sidebar-bg flex flex-col justify-between fixed lg:translate-x-0 z-111 lg:z-1 transition-transform-300"
    :class="{ 'translate-x-0': sidebarStore.isOpened, 'translate-x-[-100%]': !sidebarStore.isOpened }"
  >
    <div class="flex flex-col justify-start">
      <NuxtLink to="/" class="px-6 py-5" active-class="..">
        <p class="text-22px line-height-30px font-700">
          Logo
        </p>
      </NuxtLink>
      <nav>
        <ul class="flex flex-col px-4 gap-1">
          <li
            v-for="(element, idx) in menuElements"
            ref="menuHtmlElements"
            :key="element.name"
            class="overflow-hidden transition-height-300"
            :style="`height: ${element.isOpened ? `${getElementExpandedHeight(element, idx)}px` : '40px'}`"
          >
            <div class="flex flex-col gap-1 menu-element-inner">
              <NuxtLink
                class="
              flex justify-between items-center px-3 py-2 c-text-secondary transition-colors group font-400 cursor-pointer
              hover:c-text-secondary hover:bg-hover relative rounded-md
            "
                :to="element.to || undefined"
                @click="element.isOpened = !element.isOpened"
              >
                <div class="flex gap-2 nav-icon">
                  <Icon :name="element.icon" class="w-6 h-6 transition-colors overflow-hidden" />

                  {{ element.name }}
                </div>

                <Icon
                  v-if="element.children"
                  name="ic:round-keyboard-arrow-down"
                  class="w-6 h-6 group-hover:c-text-secondary transition-transform"
                  :class="{ 'transform rotate-180': element.isOpened }"
                />
              </NuxtLink>

              <NuxtLink
                v-for="child in element.children"
                :key="child.name"
                class="
              flex gap-2 items-center px-3 py-2 c-text-secondary transition-colors group font-400 cursor-pointer
              hover:c-text-secondary hover:bg-hover rounded-md
            "
                :to="child.to || undefined"
              >
                <div class="w-6 h-6" />

                {{ child.name }}
              </NuxtLink>
            </div>
          </li>
        </ul>
      </nav>
    </div>

    <div class="px-4 pb-5">
      <button
        class="
        flex gap-2 justify-center items-center px-3 py-2 c-text-secondary transition-colors group font-400 cursor-pointer
        hover:c-error hover:bg-red-hover w-full rounded-md
      "
        type="button"
        @click="logout"
      >
        <Icon name="ic:round-log-out" class="w-6 h-6 group-hover:c-error transition-colors" />

        Logout
      </button>
    </div>
  </aside>
</template>

<style lang="scss" scoped>
.router-link-active {
  @apply c-green-pure bg-green-hover;
}
</style>
